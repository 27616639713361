.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem;
  align-items: center;
  justify-items: unset;
}

.about__image {
  max-width: 350px;
}

.about__title {
  text-align: center;
}

.about__text {
  text-align: justify;
}

.about__arrow {
  margin: 0 auto;
  display: block;
}

@media only screen and (max-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .about__title {
    text-align: left;
    text-transform: lowercase;
  }
}

@media only screen and (max-width: 576px) {
  .about__image {
    display: none;
  }
}