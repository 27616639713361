body {
  margin: 0;
  color: var(--primary-color);
  font-family: 'Fredoka', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dark-bg-color);
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--secondary-color);
  text-decoration: none
}

a:hover {
  color: var(--details-color)
}

p {
  font-size: 1.25rem;
  font-weight: 400;
}

h1,
h2,
h3,
h4 {
  color: var(--secondary-color);
}

h1 {
  font-size: 4rem;
  font-weight: 600;
}

h2 {
  font-size: 1.88rem;
}

h3 {
  font-size: 1.5rem;
}

section {
  padding-top: 1rem;
}

.scroll-chevron {
  font-size: 40px;
  cursor: pointer;
}

.rs-timeline-item-tail,
.rs-timeline-item-dot::before {
  background-color: var(--details-color) !important;
}

.rs-timeline-item-dot::before {
  transform: scale(2.6);
}

@media only screen and (max-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 2rem;
  }
}

@media only screen and (max-width: 768px) {

  p {
    font-size: 0.875rem;
    font-weight: 400;
  }

  h1 {
    font-size: 1.7rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1rem;
  }

  .scroll-chevron {
    font-size: 24px;
    cursor: pointer;
  }

  .rs-timeline-item-dot::before {
    transform: scale(1.6);
  }

  .rs-timeline-item-tail {
    width: 1px !important;
  }
}

@media only screen and (max-width: 576px) {
  .rs-timeline-item-dot::before {
    transform: scale(1.6);
  }

  .rs-timeline-item-tail {
    width: 1px !important;
  }
}

