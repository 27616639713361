.flex {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main {
  text-align: center;
  height: 80vh;
}

.main h1 {
  margin: 0;
}

.type-writer {
  font-size: 1.75rem;
  margin: 2rem 0;
}

.main__title {
  color: var(--details-color);
}

.main__arrow {
  margin: 10rem 0 0 0;
}


@media only screen and (max-width: 768px) {
  .type-writer {
    font-size: 1rem;
    margin: 1rem 0;
  }
}