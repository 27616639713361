.container {
  width: 100%;
  padding: 1.5rem;
  margin: 0 0 2rem 0;
  border-radius: 15px;
  background: var(--details-dark-color);
}

.title {
  color: var(--details-color);
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.description {
  grid-column: 1 / 4;
  margin: 2rem 3rem;
  text-align: justify;
}

.flex-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: fit-content;
}

.language__container {
  background: var(--details-color);
  border-radius: 5px;
  margin: 0 1rem 1rem 0;
  padding: 0.5rem 1.5rem;

  font-size: 1.5rem;
  color: var(--details-dark-color);
  font-weight: 700;
}

@media only screen and (max-width: 1280px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .description {
    grid-column: 1 / 3;
  }
}

@media only screen and (max-width: 768px) {
  .language__container {
    font-size: 0.875rem;
    margin: 0 0.5rem 0.5rem 0;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .description {
    margin: 1rem 0rem;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    width: 100%;
    padding: 1.2rem;
    margin: 0 0 2rem 0;
    border-radius: 15px;
    background: var(--details-dark-color);
  }
}