.container {
  margin: 5rem 0 2rem 0;
}

.icon {
  margin: 0 auto;
  display: block;
}

.animate {  
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}