.experience__container {
  margin: 0 2rem;
}

.experience__title {
  margin: 0 0 8px 0;
}

.experience__company {
  font-size: 1.5rem;
  color: var(--details-color);
  font-weight: bold;
  margin: 0;
}

.experience__company:hover {
  color: var(--secondary-color)
}

.experience__description {
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .experience__title {
    margin: 0 0 4px 0;
  }

  .experience__company {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 576px) {
  .experience__container {
    margin: 0;
  }
}