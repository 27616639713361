.experiences__frame {
  background: var(--details-dark-color);
  border-radius: 20px;
  padding: 2rem 2rem;
}

@media only screen and (max-width: 576px) {
  .experiences__frame {
    padding: 1rem 1.2rem;
  }
}