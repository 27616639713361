.nav {
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 24px;
}

.nav .tabs {
  font-size: 1.5rem;
  font-weight: 600;
}

.social a {
  font-size: 1.875rem;
}

.nav .title {
  color: var(--details-color);
}

@media only screen and (max-width: 768px) {
  .nav {
    margin: 3rem 0;
  }

  .nav .tabs {
    font-size: 1.25rem;
  }

  .social a {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 576px) {
  .nav ul {
    gap: 18px;
  }
}