.container {
  max-width: 1280px;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 3rem;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 320px) {
  .container {
    padding: 0 1rem;
  }
}