.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}

@media only screen and (max-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .grid-container {
    /* justify-items: start; */
    grid-auto-rows: minmax(auto, auto);
  }
}